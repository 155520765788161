body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* The container for the widget */
.widget-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    max-width: 400px;
    width: 80%;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    z-index: 2000;  /* to ensure the widget stays on top of other elements */
    font-family: Arial, sans-serif;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.widget-button {
    display: block;
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 10px;
    border: none;
    background-color: #469271 !important;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
}

.widget-button:hover {
    transform: translateY(-2px);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.widget-button:active {
    transform: translateY(0);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
}

.widget-button-chat {
    background-color: #007bff;  /* Blue */
}

.widget-button-book {
    background-color: #469271;  /* Green */
}

.cs-message--outgoing .cs-message__content {
    background-color: #245A79 !important;
    color:#fff !important;
}

.cs-message--incoming .cs-message__content {
    background-color: #8CBCAF !important;
    border-radius: 30px !important;
}

.cs-conversation-header {
    border-bottom: none !important;
    background-color: #ffffff !important;
    position: sticky;
    top: 0;
    z-index: 100; /* Ensure it stays above the other content */
  }
  .cs-conversation-header__user-name{
    background-color: #ffffff !important;
  }
  .cs-message-input{
    padding: 0px !important;
    border-radius: 60px !important;
    background-color: #245A79 !important;
  }
  .cs-message-input__content-editor-wrapper{
    background-color: #245A79 !important;
  }
  .cs-message-input__content-editor{
    background-color: #245A79 !important;
    color: #ffffff !important;
  }
  .cs-message-list{
    min-height: 400px !important;
    padding: 10px
  }  
  .cs-main-container{
    border: none !important;
  }